import { DropdownPicker } from "../types/User";

export const AUTH_TOKEN_KEY = "auth_token";
export const TENANT = "tenant";
export const CHARGEBEE_BASE_URL = process.env.REACT_APP_CHARGEBEE_URL;
export const ZIG_OPS_API_BASE_URL = process.env.REACT_APP_ZIG_OPS_API_BASE_URL;
export const ZIG_OPS_SUPPORT_EMAIL = process.env.REACT_APP_ZIG_OPS_SUPPORT_EMAIL;
export const LAUNCH_DARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;

export const CORRELATION_ID = "correlation_id";
export const FULL_ACCESS = "Full access";
export const USER_TYPES = {
  member: "member",
  admin: "admin",
  owner: "owner",
};

//Chargebee payment card verification statuses
export const PAYMENT_CARD_STATUS = {
  NO_CARD: "NoCard",
  VALID: "Valid",
  EXPIRED: "Expired",
  EXPIRING: "Expiring",
};

export const EMPLOYMENT_TYPES: DropdownPicker[] = [
  { id: "fullTime", value: "Full Time" },
  { id: "contract", value: "Contract" },
  { id: "internship", value: "Internship" },
];
export const ROLES: DropdownPicker[] = [
  { id: "member", value: "Member" },
  { id: "admin", value: "Admin" },
  { id: "owner", value: "Owner" },
];
export const CURRENCIES: DropdownPicker[] = [
  { id: "usd", value: "USD" },
  { id: "zmw", value: "ZMW" },
];
export const YES_NO_DROPDOWN: DropdownPicker[] = [
  { id: "yes", value: "Yes" },
  { id: "no", value: "No" },
];

export const PROJECT_BILLING: DropdownPicker[] = [
  { id: "time&material", value: "Time & Materials" },
  { id: "fixedFee", value: "Fixed Fee" },
  { id: "fixedMonthly", value: "Fixed Monthly" },
  { id: "nonBillable", value: "Non-Billable" },
];

export const BillingTypeConstants = {
  timeMaterial: "Time & Materials",
  fixedFee: "Fixed Fee",
  fixedMonthly: "Fixed Monthly",
  nonBillable: "Non-Billable",
};

export const PayTypeConstants = {
  HourlyRate: {
    label: "Hourly rate",
    type: "Hourly",
    description:
      " An hourly rate is the amount of money your employee is paid for each hour of work they do.",
  },
  FixedRate: {
    label: "Fixed rate",
    type: "Fixed",
    description:
      "A fixed rate is the amount of money paid to your employee that remains the same regardless of the amount of work done or the time spent on a task.",
  },
};

export const DEFAULT_EMPLOYEE_COUNTRY = { country: "Zambia", currency: "ZMW" };

export const BLOB_STORAGE_CONTAINER_ZIGOPS_PROFILE =
  process.env.REACT_APP_CONTAINER_NAME || "zigops-profiles";
export const BLOB_STORAGE_LEAVE_CONTAINER_NAME = "leave-attachments";
export const BLOB_STORAGE_COMPANY_LOGOS = "company-logos";
export const BLOB_STORAGE_SASS_TOKEN = process.env.REACT_APP_STORAGESASTOKEN;
export const BLOB_STORAGE_ACCOUNT_NAME = process.env.REACT_APP_STORAGERESOURCENAME;

export const IMAGE_BLOB_STORAGE = process.env.REACT_APP_AVATAR_LOCATION;

export const EMPLOYEE_UPLOAD_TEMPLATE = process.env.REACT_APP_EMPLOYEE_UPLOAD_CSV_TEMPLATE;

/**
 * Token expiration error message
 */
export const TOKEN_EXPIRED_MESSAGE = "Your session token has expired redirecting to auth";

/**
 * Get company from DB error message
 */
export const GET_COMPANY_ERROR = "Failed to get company from Database";

/**
 * How long to wait for a query in milliseconds before timing out.
 */
export const QUERY_TIMEOUT_MILLIS = 15000;

/**
 * Represents the value of an invalid date.
 * This is the value that is returned by a date picker component when
 * an invalid date has been entered.
 */
export const INVALID_DATE = "Invalid Date";
/**
 * This date is used to represent no date.
 * Its used in this manner because the backend does not accept null dates.
 */
export const NO_DATE_REFERENCE = "0001-01-01T00:00:00Z";

/**
 * Represents a female gender.
 */
export const FEMALE_GENDER = "Female";
/**
 * Represents a male gender.
 */
export const MALE_GENDER = "Male";
/**
 * Represents domestic payroll ref.
 */
export const DOMESTIC_PAYROLL = "Domestic Payroll";

/**
 * Represents any other gender.
 */
export const OTHER_GENDER = "Other";

/**
 * Defines the name of the payrun queue.
 */
export const PAYRUN_QUEUE = "payrun";

/**
 * Defines the name of the 'beforeunload' event.
 */
export const BEFORE_UNLOAD_EVENT = "beforeunload";

/**
 * Defines the yes constant.
 */
export const YES = "yes";

/**
 * Defines the No constant.
 */
export const NO = "no";

/**
 * Defines the 401 page route.
 */
export const ROUTE_401 = "/PageError401";

/**
 * Defines the query for the current user's details.
 */
export const USER_DETAILS_QUERY = "userDetails";

/**
 * Defines the query for the current company's details.
 */
export const COMPANY_DETAILS_QUERY = "companyDetails";

export const PRN_CONSTANTS = {
  account: "1",
  accountLiability: "11",
  taxType: "3",
  taxLiability: "2",
};

/**
 * Defines the user access denied page.
 */
export const UNAUTHORIZED_ACCESS_PAGE_LINK = "/AccessDenied";

/**
 * Defines the route for employee dashboard.
 */
export const EMPLOYEE_DASHBOARD_ROUTE = "/employee/dashboard";

/**
 * Defines the route for admin dashboard.
 */
export const ADMIN_DASHBOARD_ROUTE = "/dashboard";

/**
 * Defines member user role for an employee.
 */
export const MEMBER_USER_ROLE = "member";

/**
 * Defines admin user role for an employee.
 */
export const ADMIN_USER_ROLE = "admin";

//Local cache key names here
/**
 * Defines key used to identify logged in employee cached details key.
 */
export const CACHED_USER_DETAILS_KEY = "userDetails";

/**
 * Defines key used to identify logged in tenant cached details key.
 */
export const CACHED_COMPANY_DETAILS_KEY = "company";

/**
 * Defines key used to identify logged in tenant cached employees details key.
 */
export const CACHED_EMPLOYEES_DETAILS_KEY = "employees";
/**
 * Defines the name for cuatome allowances magic string.
 */
export const CUSTOM_ALLOWANCE = "Allowance";

/**
 * Defines the name for cuatome deduction magic string.
 */
export const CUSTOM_DEDUCTION = "Deduction";

/**
 * Defines the payment options that an employee can choose from.
 */
export const PAYMENT_OPTIONS = {
  BANK_OPTION: "Bank tranfer",
  MOBILE_MONEY_OPTION: "Mobile money",
  CRYPTO_OPTION: "Crypto(coming soon)",
};

/**
 * Defines the mobile money payment method.
 */
export const MOBILE_MONEY_PAYMENT_METHOD = "Mobile money";

/**
 * Defines the bank payment method.
 */
export const BANK_TRANSFER_PAYMENT_METHOD = "Bank transfer";

/**
 * Defines the maximum number of employees allowed for the free subscription plan.
 */
export const FREE_SUBSCRIPTION_PLAN_EMPLOYEE_LIMIT =
  process.env.REACT_APP_FREE_SUBSCRIPTION_PLAN_EMPLOYEE_LIMIT;

/**
 * Defines a generic fetch error.
 */
export const FETCH_ERROR_MESSAGE = "Fetch error. Please try to refresh the page";

/**
 * Defines the VGS vault URL.
 */
export const VGS_VAULT_URL = process.env.REACT_APP_VGS_VAULT_URL;

/******************************************************************************
 * This section contains constants for employee error messages.                     *
 ******************************************************************************/

export const EMPLOYEE_DOES_NOT_EXIST =
  "We apologize, but we couldn't locate an employee with the specified ID. Please log out and sign in again to refresh your session. If the problem persists, feel free to reach out to our support team for further assistance.";

/******************************************************************************
 * This section contains constants for HTTP status codes.                     *
 ******************************************************************************/

/**
 * Defines the 'not found' status code.
 */
export const HTTP_STATUS_CODE_404 = 404;

/**
 * Defines the forbiden status code.
 */
export const HTTP_STATUS_CODE_403 = 403;

/**
 * Defines the internal server error status code.
 */
export const HTTP_STATUS_CODE_500 = 500;

/**
 * Defines the bad request status code.
 */
export const HTTP_STATUS_CODE_400 = 400;

/**
 * Defines the unauthorized status code.
 */
export const HTTP_STATUS_CODE_401 = 401;

/**
 * Defines the conflict status code.
 */
export const HTTP_STATUS_CODE_409 = 409;

/**
 * Defines the OK status code.
 */
export const HTTP_STATUS_CODE_200 = 200;

/**
 * Represents the forbidden axios error object.
 */
export const FORBIDDEN_AXIOS_ERROR_OBJECT: any = {
  response: {
    status: HTTP_STATUS_CODE_403,
  },
};

/**
 * Represents a regular expression for a short date in the format DD/MM/YYYY
 */
export const SHORT_DATE_REGEX = new RegExp(
  "^([0-2][0-9]||3[0-1])/(0[0-9]||1[0-2])/([0-9][0-9])?[0-9][0-9]$",
);

/******************************************************************************
 * This section contains constants for Employee Onboarding.                     *
 ******************************************************************************/

export interface EmployeeOnboardingType {
  title: string;
  value: string;
  helperText: string;
}

/**
 * Defines a full time employee.
 */
export const FULL_TIME_EMPLOYEE: EmployeeOnboardingType = {
  title: "Full-Time",
  value: "full_time",
  helperText:
    "Full-time employees work 35-40 hours a week and enjoy benefits such as health insurance and paid time off.",
};

/**
 * Defines a part time employee.
 */
export const PART_TIME_EMPLOYEE: EmployeeOnboardingType = {
  title: "Part-Time",
  value: "part_time",
  helperText:
    "Part-time employees work fewer hours than full-time employees and may have limited benefits.",
};

/**
 * Defines a Contract employee.
 */
export const CONTRACT_EMPLOYEE: EmployeeOnboardingType = {
  title: "Contract",
  value: "contract",
  helperText:
    "Contract employees are hired for a fixed term and may work on specific projects. They are often responsible for their own taxes and benefits.",
};

/**
 * Defines a Contract employee.
 */
export const INTERNSHIP: EmployeeOnboardingType = {
  title: "Internship",
  value: "internship",
  helperText:
    "Interns are usually students or recent graduates gaining practical experience in their field of study.",
};

/******************************************************************************
 * This section contains constants for Employee Basic Information.                     *
 ******************************************************************************/

export interface EmployeeBasicInformation {
  title: string;
  value: string;
  helperText: string;
}

/**
 * Defines a male employee.
 */
export const GENDER_MALE: EmployeeBasicInformation = {
  title: "Male",
  value: "male",
  helperText: null,
};

/**
 * Defines a female employee.
 */
export const GENDER_FEMALE: EmployeeBasicInformation = {
  title: "Female",
  value: "female",
  helperText: null,
};

/**
 * Defines marital status.
 * @var Single
 */
export const MARITAL_STATUS_SINGLE: EmployeeBasicInformation = {
  title: "Single",
  value: "Single",
  helperText: null,
};

/**
 * Defines marital status.
 * @var Married
 */
export const MARITAL_STATUS_MARRIED: EmployeeBasicInformation = {
  title: "Married",
  value: "Married",
  helperText: null,
};

/**
 * Defines marital status.
 * @var Divorced
 */
export const MARITAL_STATUS_DIVORCED: EmployeeBasicInformation = {
  title: "Divorced",
  value: "Divorced",
  helperText: null,
};

/**
 * Defines marital status.
 * @var Widowed
 */
export const MARITAL_STATUS_WINDOWED: EmployeeBasicInformation = {
  title: "Widowed",
  value: "Widowed",
  helperText: null,
};

/******************************************************************************
 * This section contains constants for company payroll.                     *
 ******************************************************************************/

/**
 * Defines a monthly period process.
 */
export const MONTHLY = "Monthly";

/**
 * Defines a process occurring every two weeks.
 */
export const BI_WEEKLY = "Bi-weekly";

/**********************************************************************************************************
 * This section contains constants for regular expression (regex) pattern used to validate email addresses.                     *
 **********************************************************************************************************/

export const EMAIL_REGEXP = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

/**********************************************************************************************************
 * This section contains constants for tax collection details from Domestic Employers.
 **********************************************************************************************************/

/**
 * Defines a server communication error message.
 */
export const DOMESTIC_TAX_COLLECTION_SERVER_RESPONSE_ERROR_MESSAGE =
  "Error uploading file to storage";

/**
 * Defines an uploading file error message.
 */
export const DOMESTIC_TAX_COLLECTION_UPLOAD_FILE_ERROR_MESSAGE = "Error uploading file:";

/**
 * Defines a server communication loading process.
 */
export const DOMESTIC_TAX_COLLECTION_PENDING_PROCESS = "Uploading your file...";

/**
 * Defines a server communication successful message.
 */
export const DOMESTIC_TAX_COLLECTION_SUCCESSFUL_MESSAGE =
  "Your file has been uploaded successfully";

export enum PROJECT_TABLE_HEAD {
  PROJECT_NAME = "Project Name",
  PROJECT_OWNER = "Project Owner",
  PROJECT_TEAM = "Team",
  PROJECT_BILLING_TYPE = "Billing Type",
  PROJECT_CREATED_ON = "Created On",
}

/**
 * Defines napsa file name.
 */
export const DOMESTIC_TAX_COLLECTION_NAPSA_FILE_NAME = "NAPSA";

/**
 * Defines nhima file name.
 */
export const DOMESTIC_TAX_COLLECTION_NHIMA_FILE_NAME = "NHIMA";

/**
 * Defines nhima file name.
 */
export const DOMESTIC_TAX_COLLECTION_TPIN_FILE_NAME = "TPIN_CERTIFICATE";

/**
 * Defines the UNIX epoch date string.
 */
export const UNIX_EPOCH_DATE_STRING = "1970-01-01T00:00:00";

/**
 * Defines the regular expression for a Zambian phone number.
 */
export const ZAMBIA_PHONE_NUMBER_REGEX = /\+260\d{9}/;

/**
 * Defines the regular expression for alphabet characters.
 */
export const ALPHA_CHARACTERS_REGEX = /^[A-Za-z\s]+$/;

/**
 * Defines the regular expression for the default date format (DD/MM/YYYY)
 */
export const DEFAULT_DATE_FORMAT_REGEX = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

/**
 * Defines the regular expression for numeric characters (whole numbers only)
 */
export const NUMERIC_CHARACTERS_REGEX = /^\d+$/;

/**
 * Defines the calling code for Zambia.
 */
export const ZAMBIA_CALLING_CODE = "+260";

/**
 * Defines a boolean that determins whether payroll runs should be limited.
 */
export const LIMIT_PAYROLL_RUNS = process.env.REACT_APP_LIMIT_PAYROLL_RUNS;


/**
 * Defines supported payment method.
 */
export enum PaymentMethods {
  BankTransfer = "Bank transfer",
  MobileMoney = "Mobile money"
}